<template>
  <div class="buttons is-right">
    <transition
      name="fade"
      mode="out-in"
    >
      <a
        v-if="!isSaving && !hideCancelButton"
        @click="onClickCancel"
        class="button is-ghost has-margin-right"
        :disabled="isSaving"
      >{{ buttonLabelCancel }}</a>
    </transition>
    <button
      v-if="!hideSaveButton"
      @click="onClickSave"
      class="button"
      :class="[
        !isSaving && !disableSaveButton ? confirmButtonCssClass : '',
        {
          'is-loading': isSaving,
          'is-disabled': isSaving || disableSaveButton,
        },
      ]"
      :disabled="isSaving || disableSaveButton"
    >
      {{ buttonLabelConfirm }}
    </button>
  </div>
</template>

<script>
import { i18n } from '@/main.js'
import { mapGetters } from 'vuex'
export default {
  props: {
    onClickCancel: {
      type: Function,
      required: true,
    },
    onClickSave: {
      type: Function,
      required: true,
    },
    disableSaveButton: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    buttonLabelConfirm: {
      type: String,
      // default: 'Save'
      default: 'Save',
    },
    confirmButtonCssClass: {
      type: String,
      default: 'is-success',
    },
    buttonLabelCancel: {
      type: String,
      // default: 'Cancel'
      default: 'Cancel',
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},
}
</script>
