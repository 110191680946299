var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttons is-right"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isSaving && !_vm.hideCancelButton)?_c('a',{staticClass:"button is-ghost has-margin-right",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.onClickCancel}},[_vm._v(_vm._s(_vm.buttonLabelCancel))]):_vm._e()]),(!_vm.hideSaveButton)?_c('button',{staticClass:"button",class:[
      !_vm.isSaving && !_vm.disableSaveButton ? _vm.confirmButtonCssClass : '',
      {
        'is-loading': _vm.isSaving,
        'is-disabled': _vm.isSaving || _vm.disableSaveButton,
      },
    ],attrs:{"disabled":_vm.isSaving || _vm.disableSaveButton},on:{"click":_vm.onClickSave}},[_vm._v(" "+_vm._s(_vm.buttonLabelConfirm)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }